import { default as _91_46_46_46slug_93tTVZEUyhkLMeta } from "/home/circleci/project/pages/[...slug].vue?macro=true";
import { default as contactyjLtKhUjWsMeta } from "/home/circleci/project/pages/contact.vue?macro=true";
import { default as creditsdhnzciiAjFMeta } from "/home/circleci/project/pages/credits.vue?macro=true";
import { default as indexbVK8ERtDnaMeta } from "/home/circleci/project/pages/index.vue?macro=true";
import { default as legaldOaYZipT72Meta } from "/home/circleci/project/pages/legal.vue?macro=true";
import { default as privacy_45generalI5Dl7m2WE9Meta } from "/home/circleci/project/pages/privacy-general.vue?macro=true";
import { default as privacyW9T5X4FEAVMeta } from "/home/circleci/project/pages/privacy.vue?macro=true";
import { default as supportDA6U4gNcDVMeta } from "/home/circleci/project/pages/support.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93tTVZEUyhkLMeta?.name ?? "slug",
    path: _91_46_46_46slug_93tTVZEUyhkLMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93tTVZEUyhkLMeta || {},
    alias: _91_46_46_46slug_93tTVZEUyhkLMeta?.alias || [],
    redirect: _91_46_46_46slug_93tTVZEUyhkLMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: contactyjLtKhUjWsMeta?.name ?? "contact",
    path: contactyjLtKhUjWsMeta?.path ?? "/contact",
    meta: contactyjLtKhUjWsMeta || {},
    alias: contactyjLtKhUjWsMeta?.alias || [],
    redirect: contactyjLtKhUjWsMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: creditsdhnzciiAjFMeta?.name ?? "credits",
    path: creditsdhnzciiAjFMeta?.path ?? "/credits",
    meta: creditsdhnzciiAjFMeta || {},
    alias: creditsdhnzciiAjFMeta?.alias || [],
    redirect: creditsdhnzciiAjFMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/credits.vue").then(m => m.default || m)
  },
  {
    name: indexbVK8ERtDnaMeta?.name ?? "index",
    path: indexbVK8ERtDnaMeta?.path ?? "/",
    meta: indexbVK8ERtDnaMeta || {},
    alias: indexbVK8ERtDnaMeta?.alias || [],
    redirect: indexbVK8ERtDnaMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legaldOaYZipT72Meta?.name ?? "legal",
    path: legaldOaYZipT72Meta?.path ?? "/legal",
    meta: legaldOaYZipT72Meta || {},
    alias: legaldOaYZipT72Meta?.alias || [],
    redirect: legaldOaYZipT72Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: privacy_45generalI5Dl7m2WE9Meta?.name ?? "privacy-general",
    path: privacy_45generalI5Dl7m2WE9Meta?.path ?? "/privacy-general",
    meta: privacy_45generalI5Dl7m2WE9Meta || {},
    alias: privacy_45generalI5Dl7m2WE9Meta?.alias || [],
    redirect: privacy_45generalI5Dl7m2WE9Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/privacy-general.vue").then(m => m.default || m)
  },
  {
    name: privacyW9T5X4FEAVMeta?.name ?? "privacy",
    path: privacyW9T5X4FEAVMeta?.path ?? "/privacy",
    meta: privacyW9T5X4FEAVMeta || {},
    alias: privacyW9T5X4FEAVMeta?.alias || [],
    redirect: privacyW9T5X4FEAVMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: supportDA6U4gNcDVMeta?.name ?? "support",
    path: supportDA6U4gNcDVMeta?.path ?? "/support",
    meta: supportDA6U4gNcDVMeta || {},
    alias: supportDA6U4gNcDVMeta?.alias || [],
    redirect: supportDA6U4gNcDVMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/support.vue").then(m => m.default || m)
  }
]