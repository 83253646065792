import Plausible, { type EventOptions, type PlausibleOptions } from 'plausible-tracker';
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(nuxtApp => {
  const options = {
    domain: 'outfit7neo.com',
    hashMode: true,
    trackLocalhost: false
  };

  const { enableAutoPageviews, trackPageview, trackEvent } = Plausible(options);

  nuxtApp.provide('plausible', () => {
    enableAutoPageviews();

    // target blank stops working afters this, also age gate…
    // enableAutoOutboundTracking();
  });

  nuxtApp.provide('event', (eventName: string, options?: EventOptions, eventData?: PlausibleOptions) => {
    trackEvent(eventName, options, eventData);
  });
  
  nuxtApp.provide('pageview', (eventData?: PlausibleOptions, options?: EventOptions) => {
    trackPageview(eventData, options);
  });
});
