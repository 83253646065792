// import type { RouterOptions } from '@nuxt/schema';
import { nextTick } from 'vue';
import { useNuxtApp } from '#app';

// Since nuxt rc-6, the default router behaviour is no longer that it goes to the top of the page
// This is a hacky way to handle this until this issue is solved:
// https://github.com/nuxt/framework/issues/6524

// https://router.vuejs.org/api/interfaces/routeroptions.html
// export default <RouterOptions>{
// 	scrollBehavior(to, _from, savedPosition) {
// 		return new Promise((resolve, _reject) => {
// 			setTimeout(() => {
// 				if (savedPosition) {
// 					resolve(savedPosition);
// 				} else {
// 					if (to.hash) {
// 						resolve({
// 							el: to.hash,
// 							top: 80
// 						});
// 					} else {
// 						resolve({ top: 0 });
// 					}
// 				}
// 			}, 5000);
// 		});
// 	}
// };

// From https://github.com/nuxt/framework/pull/8327#issuecomment-1303823445:
export default {
	scrollBehavior(to, from, savedPosition) {
		const nuxtApp = useNuxtApp();
		let position = savedPosition || void 0;
		if (!position && from && to && to.meta.scrollToTop !== false && _isDifferentRoute(from, to)) {
			position = { left: 0, top: 0 };
		}
		if (to.path === from.path) {
			if (from.hash && !to.hash) {
				return { left: 0, top: 0 };
			}
			if (to.hash) {
				return { el: to.hash, top: _getHashElementScrollMarginTop(to.hash) };
			}
		}
		const hasTransition = to.meta.pageTransition !== false && from.meta.pageTransition !== false;
		const hookToWait = 'page:finish';
		return new Promise(resolve => {
			nuxtApp.hooks.hookOnce(hookToWait, async () => {
				await nextTick();
				if (to.hash) {
					position = { el: to.hash, top: _getHashElementScrollMarginTop(to.hash) };
				}
				resolve(position);
			});
		});
	}
};
function _getHashElementScrollMarginTop(selector) {
	const elem = document.querySelector(selector);
	if (elem) {
		return parseFloat(getComputedStyle(elem).scrollMarginTop);
	}
	return 0;
}
function _isDifferentRoute(a, b) {
	const samePageComponent = a.matched[0] === b.matched[0];
	if (!samePageComponent) {
		return true;
	}
	if (samePageComponent && JSON.stringify(a.params) !== JSON.stringify(b.params)) {
		return true;
	}
	return false;
}
