<template>
	<div id="app">
		<NuxtLayout>
			<NuxtPage />
		</NuxtLayout>
	</div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useNuxtApp } from '#imports';

const { $plausible } = useNuxtApp();

onMounted(() => {
  $plausible();
});
</script>

<style lang="scss">
@import '~/assets/design';
</style>
